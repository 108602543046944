import { Box, CircularProgress } from '@mui/material'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useIsLoggedIn } from 'src/modules/auth/hooks'
import { Path } from 'src/modules/path'

type Props = {
  children: React.ReactNode
}

export const LoginRequired: React.FC<Props> = ({ children }) => {
  const { isInit, isLoggedIn } = useIsLoggedIn()
  const router = useRouter()

  useEffect(() => {
    if (isInit && !isLoggedIn) {
      router.replace(Path.authSignIn)
    }
  }, [isInit, isLoggedIn, router])

  if (!isInit)
    return (
      <Box sx={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )

  if (!isLoggedIn) {
    return <></>
  }
  return <>{children}</>
}
