import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'

import Link from 'next/link'
import React, { FC } from 'react'
import { Layout } from 'src/components/organisms/Layout'
import { useSignOut } from 'src/modules/auth/hooks'
import { useGetNavigationMenuList } from 'src/utils/app'

export const RootPage: FC = () => {
  const { handleSignOut } = useSignOut()
  const menus = useGetNavigationMenuList()
  return (
    <Layout hideDrawer>
      <Stack sx={{ alignItems: 'center', justifyContent: 'center', py: '32px', height: '100%' }}>
        {menus.map(item => {
          return (
            <Box key={item.label} sx={{ py: '8px' }}>
              <Link href={item?.path || '/'} style={{ color: 'rgba(0, 0, 0, 0.8)', textDecoration: 'none' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{item.label}</Typography>
              </Link>
            </Box>
          )
        })}

        <Box sx={{ mt: '32px' }}>
          <Box
            onClick={() => {
              const ok = window.confirm('ログアウトしますか')
              if (ok) {
                handleSignOut().then(() => window.alert('ログアウトしました。'))
              }
            }}
          >
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', textDecoration: 'none' }}>{'ログアウト'}</Typography>
          </Box>
        </Box>
      </Stack>
    </Layout>
  )
}
