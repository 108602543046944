import { NextPage } from 'next'
import React from 'react'
import { LoginRequired } from 'src/components/organisms/LoginRequired'
import { RootPage } from 'src/components/pages/root'

type Props = {}

const Page: NextPage<Props> = () => {
  return (
    <>
      <LoginRequired>
        <RootPage />
      </LoginRequired>
    </>
  )
}

export default Page
